
  
  .container {
    width: 100%;
    height: 10vh;
    top: 5%;
    left: 5%;
  }
  
  .sigContainer {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    background-color: #fff;
  }
  
  .sigPad {
    width:200px;
    height: 250px;
    padding: 10px;
  }
  
  .buttons {
    width: 62%;
    height: 30px;
    text-align:center;
    margin-bottom:10px;
    background:rgb(78, 190, 255);
    border: none;
    border-radius: 25px;
    color: floralwhite;
  }
  
  .sigImage {
    background-size: 200px 100px;
    width: 200px;
    height: 80px;
    background-color: white;
    margin:auto;
  }
  
  /* popup css */

  .popup-data{
    position: relative;
    background: rgb(255, 255, 255);
    max-width:550px !important;
    margin:100px auto auto !important;
    border: 1px solid rgb(187, 187, 187);
    padding: 5px;
}
  

#sign-location{
  overflow:visible;
  position:absolute;
}
body{
  background-color:white;
}
#contract-area{
  border-radius:5px;
  margin:10px;
  padding:10px;
background:antiquewhite;
width:100%;
  height:200px;
  overflow-y:scroll;
}
.signature-container{
  border-radius:5px;
  margin:10px;
  padding:10px;
  background-color:darkgrey;
width:450px;
}
.signature-input-wrapper{
  border: 1px solid black;
  margin: 0 auto;
  padding: 5px auto;  
}
.signature-input{
  transition: 1s all ease-in-out;
  background-color:rgba(255,255,255,.2);
  width:100%;
}
  .signature-input.hover {
    background-color:rgba(255,255,255,.7)
  }

.signature-button-wrapper{
  display:block;
}
